import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import Screens from '..';
import RouteParamConstants from '../../utils/routeParamConstants';
import store from '../../state/store';
import { addPendingMessage } from '../../state/actions/pendingMessage';
var testDailyBonusData = {
  SPINNER_ITEMS: [{
    CURRENCY: 'coins',
    AMOUNT: 2800,
    ORDER: 1
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3300,
    ORDER: 2
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3600,
    ORDER: 3
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3900,
    ORDER: 4
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4200,
    ORDER: 5
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4500,
    ORDER: 6
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4800,
    ORDER: 7
  }, {
    CURRENCY: 'coins',
    AMOUNT: 5400,
    ORDER: 8
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3000,
    ORDER: 9
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3300,
    ORDER: 10
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3600,
    ORDER: 11
  }, {
    CURRENCY: 'coins',
    AMOUNT: 3900,
    ORDER: 12
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4200,
    ORDER: 13
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4500,
    ORDER: 14
  }, {
    CURRENCY: 'coins',
    AMOUNT: 4800,
    ORDER: 15
  }, {
    CURRENCY: 'coins',
    AMOUNT: 5400,
    ORDER: 16
  }],
  PRIZE_INDEX: 1,
  LOGIN_STREAK_BONUS: 0,
  LOGIN_STREAK_COINS_LIST: [{
    amount: 1000
  }, {
    amount: 2000
  }, {
    amount: 3000
  }, {
    amount: 4000
  }, {
    amount: 5000
  }, {
    amount: 6000
  }, {
    amount: 7000
  }, {
    amount: 8000
  }, {
    amount: 9000
  }, {
    amount: 10000
  }],
  LOGIN_STREAK_LOYALTY_LIST: [{
    amount: '100'
  }, {
    amount: '200'
  }, {
    amount: '300'
  }, {
    amount: '400'
  }, {
    amount: '500'
  }, {
    amount: '600'
  }, {
    amount: '700'
  }, {
    amount: '800'
  }, {
    amount: '900'
  }, {
    amount: '999'
  }],
  DAILY_BONUS_CURRENCY: 'coins',
  CURRENT_REWARD_DAY: 1,
  MEMBER_TIERS: [{
    daily_bonus_boost_percentage: 0,
    tier_name: 'Unlinked',
    points_required: 0
  }, {
    daily_bonus_boost_percentage: 10,
    tier_name: 'Bronze',
    points_required: 0
  }, {
    daily_bonus_boost_percentage: 20,
    tier_name: 'Silver',
    points_required: 100
  }, {
    daily_bonus_boost_percentage: 30,
    tier_name: 'Gold',
    points_required: 1500
  }, {
    daily_bonus_boost_percentage: 40,
    tier_name: 'Platinum',
    points_required: 5000
  }, {
    daily_bonus_boost_percentage: 50,
    tier_name: 'Diamond',
    points_required: 9223372036854776000
  }],
  CURRENT_MEMBER_TIER: 'Unlinked',
  RESPIN_COST: null,
  LOGIN_STREAK_BONUS_LIST: [{
    coins: 1000,
    loyalty: 100,
    coins_trunc: '1K',
    loyalty_trunc: '100'
  }, {
    coins: 2000,
    loyalty: 200,
    coins_trunc: '2K',
    loyalty_trunc: '200'
  }, {
    coins: 3000,
    loyalty: 300,
    coins_trunc: '3K',
    loyalty_trunc: '300'
  }, {
    coins: 4000,
    loyalty: 400,
    coins_trunc: '4K',
    loyalty_trunc: '400'
  }, {
    coins: 5000,
    loyalty: 500,
    coins_trunc: '5K',
    loyalty_trunc: '500'
  }, {
    coins: 6000,
    loyalty: 600,
    coins_trunc: '6K',
    loyalty_trunc: '600'
  }, {
    coins: 7000,
    loyalty: 700,
    coins_trunc: '7K',
    loyalty_trunc: '700'
  }, {
    coins: 8000,
    loyalty: 800,
    coins_trunc: '8K',
    loyalty_trunc: '800'
  }, {
    coins: 9000,
    loyalty: 900,
    coins_trunc: '9K',
    loyalty_trunc: '900'
  }, {
    coins: 10000,
    loyalty: 999,
    coins_trunc: '10K',
    loyalty_trunc: '999'
  }]
};
export default _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Screens.PurchaseFlow, {
  params: _defineProperty(_defineProperty(_defineProperty({}, RouteParamConstants.PARAM_PURCHASE_SKU, '6'), RouteParamConstants.PARAM_PURCHASE_TYPE, 'currency'), RouteParamConstants.PARAM_PURCHASE_NAME, 'StarPlay $199.99 Coin Package')
}), Screens.UpgradeAccountFlow, {
  params: _defineProperty({}, RouteParamConstants.PARAM_UPGRADE_TYPE, 'combined')
}), Screens.GenericMessage, {
  setup: function setup() {
    store.dispatch(addPendingMessage({
      type: 'BONUS_LINK',
      msg: 'You have won a lot of stuff!',
      success: true,
      redeem: {
        currency: 'coin',
        amount: 10000
      },
      isFake: true
    }));
  }
}), Screens.BonusLinkMessage, {
  setup: function setup() {
    store.dispatch(addPendingMessage({
      type: 'BONUS_LINK',
      msg: 'You have won a lot of stuff!',
      success: true,
      redeem: {
        currency: 'coin',
        amount: 10000
      },
      isFake: true
    }));
  }
}), Screens.FavouritesExpired, {
  params: _defineProperty({}, RouteParamConstants.PARAM_MSG, 'Some of your VIP favourite games expired. Get VIP access again')
}), Screens.DailySpin, {
  setup: function setup() {}
});