import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { useCallback } from 'react';
import DynamicAction from '../../../utils/DynamicAction';
import { DynamicActionKey } from '../../../utils/DynamicAction/DynamicAction';
import { ASSET_KEY } from '../../../utils/assetSource';
import { BANNER_TYPE } from '../constants/constants';
import { useEffect } from 'react';
var useBannerItem = function useBannerItem(_ref) {
  var navigation = _ref.navigation,
    scrolling = _ref.scrolling;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openBanner = _useState2[0],
    setOpenBanner = _useState2[1];
  var toggleBanner = useCallback(function () {
    setOpenBanner(function (prev) {
      return !prev;
    });
  }, []);
  var handleOpenGame = useCallback(function (gameId) {
    DynamicAction.getActionCallback("".concat(DynamicActionKey.LAUNCH_GAME, ":").concat(gameId), navigation)();
  }, [navigation]);
  var bannerBackground = _defineProperty(_defineProperty({}, BANNER_TYPE.YOU_MIGHT_LIKE, ASSET_KEY.LOBBY_BANNERS_YOU_MIGHT_LIKE), BANNER_TYPE.NOW_TRENDING, ASSET_KEY.LOBBY_BANNERS_NOW_TRENDING);
  useEffect(function () {
    scrolling && setOpenBanner(false);
  }, [scrolling, setOpenBanner]);
  return {
    openBanner: openBanner,
    setOpenBanner: setOpenBanner,
    toggleBanner: toggleBanner,
    handleOpenGame: handleOpenGame,
    bannerBackground: bannerBackground
  };
};
export default useBannerItem;